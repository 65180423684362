import { createPinia } from "pinia";
import useAppStore from "./modules/app";
import useUserStore from "./modules/user";
import useConversationStore from "./modules/conversation";
import usePayStore from "./modules/pay";
import usePromptStore from "./modules/prompt";

const pinia = createPinia();

export {
  useAppStore,
  useUserStore,
  useConversationStore,
  usePayStore,
  usePromptStore,
};
export default pinia;
