import * as ApiPay from "@/api/pay"
import { defineStore } from "pinia"


/**
 * import { usePayStore } from "@/store"
 * const payStore = usePayStore()
 * payStore.xxx
 */
const usePayStore = defineStore("pay", {
  state: () => ({
    // 即将过期的询问次数和过期时间
    expiringSoon: {
      count: '',
      time: ''
    }
  }),
  getters: {
  },

  actions: {
    // 购买并前往支付，跳转前的接口
    async buyPaymentApi(count: number) {
      const res = await ApiPay.buyPaymentApi(count)
      return res
    },
    
    // 获取支付状态，成功还是正在支付
    async getCheckPayStatusApi(order_id: any) {
      const res = await ApiPay.getCheckPayStatusApi(order_id)
      return res
    },
    
    // 获取即将过期的询问次数和过期时间
    async getPayExpiringSoonApi() {
      const res = await ApiPay.getPayExpiringSoonApi()
      if (res.data) {
        this.expiringSoon = res.data
      }
      return res
    },
    
    // VIP 直接购买次数
    async setVIPBuyPaymentApi(count: number, user_id: number, expire_days: number) {
      const res = await ApiPay.VIPBuyPaymentApi(count, user_id, expire_days)
      return res
    },
    
    // 获取当前 GPT4 Token
    async getGPT4Token() {
      const res = await ApiPay.getGPT4Token()
      return res
    },
    
    // 更新当前 GPT4 Token
    async updateGPT4Token(new_token: string) {
      const res = await ApiPay.updateGPT4Token(new_token)
      return res
    },
  },
});

export default usePayStore
