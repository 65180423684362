import { createRouter, createWebHistory } from "vue-router";
import createRouteGuard from "./guard";

const router = createRouter({
  history: createWebHistory(
    import.meta.env.VITE_ROUTER_BASE
  ),
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/index.vue"),
      meta: {
        requiresAuth: false,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/components/Layout.vue"),
      children: [
        {
          path: "home",
          name: "home",
          component: () => import("@/views/home.vue"),
          meta: {
            requiresAuth: false,
            roles: ["superuser", "user"],
          },
        },
        {
          path: "conversation",
          name: "conversation",
          component: () => import("@/views/conversation/index.vue"),
          meta: {
            requiresAuth: true,
            roles: ["superuser", "user"],
          },
        },
        {
          path: "conv/:conversation_id",
          name: "conversationHistory",
          component: () => import("@/views/conversation/history-viewer.vue"),
          meta: {
            requiresAuth: true,
            roles: ["superuser", "user"],
          },
        },
        {
          path: "admin",
          name: "admin",
          redirect: "/admin/system",
          component: () => import("@/views/admin/index.vue"),
          meta: {
            requiresAuth: true,
            roles: ["superuser"],
          },
          children: [
            {
              path: "system",
              name: "systemManagement",
              component: () => import("@/views/admin/system_manager.vue"),
            },
            {
              path: "user",
              name: "userManagement",
              component: () => import("@/views/admin/user_manager.vue"),
            },
            {
              path: "conversation",
              name: "conversationManagement",
              component: () => import("@/views/admin/conversation_manager.vue"),
            },
            {
              path: "log",
              name: "logViewer",
              component: () => import("@/views/admin/log_viewer.vue"),
            },
            {
              path: "api",
              name: "apiManagement",
              component: () => import("@/views/admin/api_manager.vue"),
            },
            {
              path: "token",
              name: "tokenManagement",
              component: () => import("@/views/admin/token_manager.vue"),
            },
          ],
        },
        {
          path: "redirect",
          name: "redirectWrapper",
          children: [
            {
              path: ":path",
              name: "Redirect",
              component: () => import("@/views/redirect/index.vue"),
              meta: {
                requiresAuth: false,
                roles: ["superuser", "user"],
              },
            },
          ],
        },
        {
          path: "error",
          name: "errorPageWrapper",
          children: [
            {
              path: "403",
              name: "403",
              component: () => import("@/views/error/403.vue"),
              meta: {
                requiresAuth: false,
                roles: ["superuser", "user"],
              },
            },
            {
              path: "404",
              name: "404",
              component: () => import("@/views/error/404.vue"),
              meta: {
                requiresAuth: false,
                roles: ["superuser", "user"],
              },
            },
          ],
        },
      ]
    },
    { path: "/:pathMatch(.*)*", name: "NotFound", redirect: "/error/404" },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

createRouteGuard(router);

export default router;
