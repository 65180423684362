import { ApiCreate, ApiRead, UserApiCreate, UserApiRead } from "@/types/schema";
import axios from "axios";

/**
 * 获取即将过期的询问次数和过期时间
 */
export function getPayExpiringSoonApi() {
  return axios.get('/user/expire_info');
}

/**
 * 获取支付状态，成功还是正在支付
 */
export function getCheckPayStatusApi(order_id: any){
  return axios.get('/payment/order_status', {
    params: {
      order_id
    }
  });
}

/**
 * 购买并前往支付，跳转前的接口
 * count 次数
 */
export function buyPaymentApi(count: number){
  return axios.post('/payment/buy', {
    count
  });
}

/**
 * 直接购买次数 VIP 管理员才有权限控制
 * count 次数
 */
export function VIPBuyPaymentApi(count: number, user_id: number, expire_days: number){
  return axios.post('/system/add_api_count', {
    count,
    user_id,
    expire_days
  });
}

/**
 * 获取当前 GPT4 Token
 */
export function getGPT4Token(){
  return axios.get('/system/get_access_token');
}

/**
 * 更新当前 GPT4 Token
 */
export function updateGPT4Token(new_token: string){
  return axios.post('/system/update_access_token', {
    new_token,
    validate_token: true
  });
}
