import { useLocalStorage } from "@vueuse/core"
import { defineStore } from "pinia"
import { ref } from "vue"

interface DataProps {
  key: string
  value: string
}

/**
 * import { usePromptStore } from "@/store"
 * const promptStore = usePromptStore()
 * promptStore.xxx
 */
const usePromptStore = defineStore("promptStore", () => {
  
  // 黑名单的 key
  const blackList = [
    '澀澀',
    '涩涩',
    '好耶！魅魔！',
  ]
  const existIllegalWord = (target: string) => {
    return blackList.some((_word) => {
      return _word.includes(target) || target.includes(_word)
    })
  }


  const promptOptionsList = useLocalStorage(
    'promptOptionsList',
    ref<Array<DataProps>>([])
  )

  
  /**
   * 在线 prompt 模板预设 List
   */
  const promptTemplateList = [
    {
      "key": "awesome-chatgpt-prompts-zh",
      "desc": "ChatGPT 中文调教指南",
      "downloadUrl": "https://raw.githubusercontent.com/PlexPt/awesome-chatgpt-prompts-zh/main/prompts-zh.json",
      "url": "https://github.com/PlexPt/awesome-chatgpt-prompts-zh"
    }
  ]
  
  /**
   * prompt 模板在线获取
   */
  const downloadPromptTemplate = async (downloadURL: string) => {
    const response = await fetch(downloadURL)
    const jsonData = await response.json()
  
    let tempPromptValue = ''
    
    if ('key' in jsonData[0] && 'value' in jsonData[0]) {
      tempPromptValue = JSON.stringify(jsonData)
    }
    
    if ('act' in jsonData[0] && 'prompt' in jsonData[0]) {
      const newJsonData = jsonData.map((item: { act: string; prompt: string }) => {
        return {
          key: item.act,
          value: item.prompt,
        }
      })
      tempPromptValue = JSON.stringify(newJsonData)
    }
  
    return tempPromptValue
  }
  
  /**
   * prompt 模板在线导入
   */
  const importPromptTemplate = (toBeConvertedWords: string) => {
    try {
      const jsonData = JSON.parse(toBeConvertedWords)
      let key = ''
      let value = ''
      // 可以扩展加入更多模板字典的key
      if ('key' in jsonData[0]) {
        key = 'key'
        value = 'value'
      }
      else if ('act' in jsonData[0]) {
        key = 'act'
        value = 'prompt'
      }
      else {
        // 不支持的字典的key防止导入 以免破坏prompt商店打开
        // message.warning('prompt key not supported.')
        throw new Error('prompt key not supported.')
      }
  
      for (const i of jsonData) {
        if (!(key in i) || !(value in i)) {
          throw new Error('导入失败')
        }
        let safe = true
        for (const j of promptOptionsList.value) {
          if (j.key === i[key]) {
            // message.warning(t('store.importRepeatTitle', { msg: i[key] }))
            safe = false
            break
          }
          if (j.value === i[value]) {
            // message.warning(t('store.importRepeatContent', { msg: i[key] }))
            safe = false
            break
          }
        }
        if (safe && !existIllegalWord(i[key]))
        promptOptionsList.value.unshift({ key: i[key], value: i[value] } as never)
      }
      // message.success(t('common.importSuccess'))
    }
    catch {
      // message.error('JSON 格式错误，请检查 JSON 格式')
    }
  }

  const fetchAllPromptDataset = async () => {
    for (let index = 0; index < promptTemplateList.length; index++) {
      const _promptPreset = promptTemplateList[index]

      const _json = await downloadPromptTemplate(_promptPreset.downloadUrl)
      importPromptTemplate(_json)
    }
    console.log('🌛', promptOptionsList.value)
  }

  return {
    promptOptionsList,
    fetchAllPromptDataset
  }

})

export default usePromptStore













