<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import PageHeader from './components/PageHeader.vue';
import { useRoute } from 'vue-router';
import { useBasicLayout, useTheme } from '@/hooks'


const route = useRoute()

const { isMobile } = useBasicLayout()
const { theme, themeOverrides } = useTheme()

</script>
