import type { GlobalThemeOverrides } from 'naive-ui'
import { computed, watch } from 'vue'
import { darkTheme, useOsTheme } from 'naive-ui'
import { useAppStore } from '@/store'

const baseThemeOverrides: GlobalThemeOverrides = {
  common: {
    borderRadius: '8px',
  },
  Menu: {
    itemTextColor: '#fff',
    itemIconColor: '#fff',
    itemColorHover: '#314376',
    itemTextColorHover: '#fff',
    itemColorActive: '#3a86ef',
    itemColorActiveHover: '#3a86ef',
    itemTextColorActiveHover: '#fff',
    itemTextColorActive: '#fff',
    itemIconColorHover: '#fff',
    itemIconColorActive: '#fff',
    itemIconColorActiveHover: '#fff',
  }
}

export const themeOverridesDark: GlobalThemeOverrides = {
  common: {
    ...baseThemeOverrides.common,
    primaryColor: '#3a86ef',
    primaryColorHover: '#448ff5',
    primaryColorPressed: '#1d76f1',
    primaryColorSuppl: '#1d76f1',

    infoColor: '#3a86ef',
    infoColorHover: '#448ff5',
    infoColorPressed: '#1d76f1',
    infoColorSuppl: '#1d76f1',
  },
  Button: {
    textColorPrimary: '#fff',
    textColorHoverPrimary: '#fff',
    textColorPressedPrimary: '#fff',
    textColorFocusPrimary: '#fff'
  },
  Input: {
    iconColor: '#878a95',
    placeholderColor: '#c8c9cc',
    iconColorHover: '#878a95',
  },
  Menu: {
    ...baseThemeOverrides.Menu
  },
}
export const themeOverridesLight: GlobalThemeOverrides = {
  common: {
    ...baseThemeOverrides.common,
    primaryColor: '#3a86ef',
    primaryColorHover: '#448ff5',
    primaryColorPressed: '#1d76f1',
    primaryColorSuppl: '#1d76f1',

    infoColor: '#3a86ef',
    infoColorHover: '#448ff5',
    infoColorPressed: '#1d76f1',
    infoColorSuppl: '#1d76f1',
  },
  Menu: {
    ...baseThemeOverrides.Menu
  },
}

export function useTheme() {
  const appStore = useAppStore()

  const OsTheme = useOsTheme()

  const isDark = computed(() => {
    if (appStore.theme === 'auto')
      return OsTheme.value === 'dark'
    else
      return appStore.theme === 'dark'
  })

  const theme = computed(() => {
    return isDark.value ? darkTheme : undefined
  })

  const themeOverrides = computed<GlobalThemeOverrides>(() => {
    if (isDark.value) {
      return themeOverridesDark
    }
    return themeOverridesLight
  })

  watch(
    () => isDark.value,
    (dark) => {
      if (dark) {
        document.documentElement.classList.add('dark')
        document.documentElement.classList.remove('light')
      }
      else {
        document.documentElement.classList.add('light')
        document.documentElement.classList.remove('dark')
      }
    },
    { immediate: true },
  )

  return { isDark, theme, themeOverrides }
}
